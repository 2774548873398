import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #f8f9fa;
`

export const ContactWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  h1 {
    margin-bottom: 2rem;
    font-size: 26pt;
    color: #212121;
  }

  h5 {
    font-size: 18px;
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    color: #6c757d !important;
  }

  .btn-cta {
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(0, 0, 0, 0.3);
    color: #000;
    display: block;
    flex: 1;
    font-size: 16px;
    padding: 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.4s ease-in-out;
    width: 200px;
    &:after,
    &:before {
      border: 2px solid rgba(0, 0, 0, 0.5);
      content: '';
      height: 30px;
      opacity: 0;
      position: absolute;
      transition: all 0.4s ease-in-out;
      width: 30px;
    }
    &:after {
      border-bottom: 0;
      border-left: 0;
      right: 0;
      top: 0;
    }
    &:before {
      border-right: 0;
      border-top: 0;
      bottom: 0;
      left: 0;
    }
    &:after {
      opacity: 1;
      right: -8px;
      top: -8px;
    }
    &:before {
      bottom: -8px;
      left: -8px;
      opacity: 1;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(0, 0, 0, 0.3);
      color: #000;
      &:after {
        opacity: 1;
        right: -2px;
        top: -2px;
      }
      &:before {
        bottom: -2px;
        left: -2px;
        opacity: 1;
      }
    }
  }

  .text-muted a {
    color: #6c757d !important;
  }

  .text-muted a:hover {
    color: #989da5 !important;
  }
`

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`
