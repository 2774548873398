import overlayImg from '../../../assets/images/intro/intro-bg.jpg'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background-image: url(${overlayImg});
  background-position: center center;
  background-size: cover;
`

export const IntroWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  max-width: 800px;
  padding: 4rem 0;

  h1 {
    color: #fff;
    text-align: center;
    font-size: 3rem;
    text-transform: uppercase
    font-weight: bold;
  }

  a {
    background-color: rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    display: block;
    font-size: 16px;
    margin: 40px 5px 5px 5px;
    padding: 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.4s ease-in-out;
    &:after,
    &:before {
      border: 2px solid rgba(255, 255, 255, 0.5);
      content: '';
      height: 30px;
      opacity: 0;
      position: absolute;
      transition: all 0.4s ease-in-out;
      width: 30px;
    }
    &:after {
      border-bottom: 0;
      border-left: 0;
      right: 0;
      top: 0;
    }
    &:before {
      border-right: 0;
      border-top: 0;
      bottom: 0;
      left: 0;
    }
    &:after {
        opacity: 1;
        right: -8px;
        top: -8px;
      }
      &:before {
        bottom: -8px;
        left: -8px;
        opacity: 1;
      }
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.3);
      color: #fff;
      &:after {
        opacity: 1;
        right: -2px;
        top: -2px;
      }
      &:before {
        bottom: -2px;
        left: -2px;
        opacity: 1;
      }
    }
  }
`
