import { AboutWrapper, Details, Thumbnail, Wrapper } from './styles'
import { graphql, useStaticQuery } from 'gatsby'

import { Container } from '../../common'
import Img from 'gatsby-image'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const About = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query AboutImageQuery {
      file(relativePath: { eq: "images/about/kevin-labory-portrait.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Wrapper id="about">
      <AboutWrapper as={Container}>
        <Thumbnail>
          <div>
            <Img fluid={data.file.childImageSharp.fluid} />
          </div>
        </Thumbnail>
        <Details>
          <div>
            <div>
              <h2>
                <span className="font-weight-bold">{t('About')}</span>
              </h2>
              <h4 className="mt-4">
                Hello!{' '}
                <span className="text-custom font-weight-bold">
                  I'm Kevin Labory.
                </span>
              </h4>
              <p className="text-muted mt-4">
                {t(
                  'I am CTO and back-end developer located in Brest, Brittany. I have 10 years of experience in IT development and web technologies in agencies and in several startups. Today, I help young companies and those with a formal approach to innovation to design the architecture of their APIs, databases, web and mobile applications.'
                )}
              </p>
              <p className="text-muted mt-2">
                {t(
                  'My passion for new technologies leads me to constantly train myself in order to master tools such as Git, MongoDB, GraphQL, Node.js or React.'
                )}
              </p>
              <div>
                <ul className="mb-0 list-inline mt-4">
                  <li className="list-inline-item">
                    <a label="twitter" href="https://twitter.com/kevinlabory">
                      <i className="mdi mdi-twitter"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      label="linkedin"
                      href="https://www.linkedin.com/in/kevinlabory/"
                    >
                      <i className="mdi mdi-linkedin"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a label="github" href="https://github.com/kevinlabory">
                      <i className="mdi mdi-github"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Details>
      </AboutWrapper>
    </Wrapper>
  )
}
