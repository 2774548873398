import { Details, ServicesWrapper, Wrapper } from './styles'

import { Container } from '../../common'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Services = () => {
  const { t } = useTranslation()
  return (
    <Wrapper id="services">
      <ServicesWrapper as={Container}>
        <Details>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <h2>
                  <span className="font-weight-bold">Services</span>
                </h2>
                <p className="text-muted mx-auto section-subtitle mt-3">
                  {t(
                    'I will be your privileged contact person throughout the entire process'
                  )}
                  .{' '}
                  {t(
                    'If you have any requests that go beyond my range of services, I can rely on a network of competent freelancers'
                  )}
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div key={`service-0`} className="col-lg-4">
              <div className="text-center services-boxes p-4 mt-4">
                <div className="services-boxes-icon">
                  <i className={`mbri-devices text-custom display-4`}></i>
                </div>
                <div className="mt-4">
                  <h5 className="mb-0">{t('Consulting & Software Expertise')}</h5>
                  <div className="services-title-border"></div>
                  <p>
                    {t(
                      'Integrate new technologies that must coexist and interoperate with existing ones'
                    )}
                    .
                  </p>
                  <ul>
                    <li>{t('JS application audit')}.</li>
                    <li>{t('DevOps (automation et collaboration)')}.</li>
                    <li>
                      {t('Agile coaching with the end-user at the center')}.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div key={`service-1`} className="col-lg-4">
              <div className="text-center services-boxes p-4 mt-4">
                <div className="services-boxes-icon">
                  <i className={`mbri-code text-custom display-4`}></i>
                </div>
                <div className="mt-4">
                  <h5 className="mb-0">{t('Application Development')}</h5>
                  <div className="services-title-border"></div>
                  <p>
                    {t(
                      'Following the philosophy of using serverless architecture to create modern web experiences'
                    )}
                    .
                  </p>
                  <ul>
                    <li>{t('Scalable and robust REST or GraphQL APIs')}.</li>
                    <li>{t('Web applications Node.js, React, Gatsby')}.</li>
                    <li>
                      {t(
                        'Git and CI to optimize deployment for a faster development workflow'
                      )}
                      .
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div key={`service-2`} className="col-lg-4">
              <div className="text-center services-boxes p-4 mt-4">
                <div className="services-boxes-icon">
                  <i className={`mbri-idea text-custom display-4`}></i>
                </div>
                <div className="mt-4">
                  <h5 className="mb-0">{t('Innovation & Digital')}</h5>
                  <div className="services-title-border"></div>
                  <p>
                    {t(
                      'Professional and personalised services that will help you build an MVP for your team quickly and efficiently'
                    )}
                    .
                  </p>
                  <ul>
                    <li>{t('Technical Feasibility Study')}.</li>
                    <li>
                      {t(
                        'Assistance in the design of a Minimum Viable Product'
                      )}
                      .
                    </li>
                    <li>
                      {t(
                        'Accompaniment in the implementation of no-code tools'
                      )}
                      .
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Details>
      </ServicesWrapper>
    </Wrapper>
  )
}
