import {
  About,
  Contact,
  Intro,
  Projects,
  Services,
} from '../components/landing'
import { Layout, SEO } from '../components/common'

import React from 'react'

export default () => (
  <Layout>
    <SEO />
    <Intro />
    <About />
    <Services />
    <Projects />
    <Contact />
  </Layout>
)
