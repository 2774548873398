import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #f8f9fa;
`

export const ServicesWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  ul {
    list-style-type: circle;
    padding-inline-start: 10px;

    li {
      text-align: left;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  .services-boxes {
    border: 1px solid #e8e8e8;
    position: relative;
    &:after,
    &:before {
      opacity: 0;
      transition: all 0.4s ease-in-out;
      border: 1px solid rgba(0, 0, 0, 0.1);
      content: '';
      height: 40px;
      position: absolute;
      width: 40px;
    }
    &:after {
      border-bottom: 0;
      border-left: 0;
      right: 0;
      top: 0;
    }
    &:before {
      border-right: 0;
      border-top: 0;
      bottom: 0;
      left: 0;
    }
    &:after {
      opacity: 1;
      right: -10px;
      top: -10px;
    }
    &:before {
      bottom: -10px;
      left: -10px;
      opacity: 1;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
      color: rgba(84, 91, 98, 1) !important;
      &:after {
        opacity: 1;
        right: -1px;
        top: -1px;
      }
      &:before {
        bottom: -1px;
        left: -1px;
        opacity: 1;
      }
    }
  }

  .services-boxes .services-boxes-icon i {
    font-size: 44px;
  }

  .services-boxes .services-title-border {
    height: 2px;
    width: 40px;
    margin: 15px auto;
    transition: all 0.5s;
  }

  .services-boxes:hover .services-title-border {
    width: 60px;
  }

  .services-boxes h5 {
    font-weight: 600;
  }
`
