import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #fff;
`

export const ProjectsWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .work-item {
    position: relative;
    padding: 15px;

    &:after,
    &:before {
      opacity: 1
      transition: opacity 0.3s ease-in-out;
      border: 2px solid rgba(84, 91 , 98, 1);
      content: "";
      height: 100px;
      position: absolute;
      width: 100px;
    }
    &:after {
      border-bottom: 0;
      border-left: 0;
      right: 0;
      top: 0;
    }
    &:before {
      border-right: 0;
      border-top: 0;
      bottom: 0;
      left: 0;
    }
  }

  .work-box {
    overflow: hidden;
    position: relative;
  }

  .work-box .work-img {
    position: relative;
    overflow: hidden;
  }

  .work-box .work-img:after {
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
  }

  .work-box .work-img > img {
    transition: all 0.3s cubic-bezier(0.3, 0.1, 0.58, 1);
    border-radius: 0;
  }

  .work-box .work-detail {
    opacity: 0;
    color: #ffffff;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    overflow: hidden;
    transition: all 0.27s cubic-bezier(0.3, 0.1, 0.58, 1);
  }

  .work-box .work-detail h4 {
    font-size: 18px;
  }

  .work-box .work-detail p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
  }

  .work-box:hover .work-detail {
    top: 50%;
    transform: translate(0, -50%);
    opacity: 1;
  }

  .work-box:hover .work-img:after {
    background: rgba(0, 0, 0, 0.8);
  }

  .work-box:hover .work-img > img {
    transform: scale(1.05);
  }
`

export const Details = styled.div`
  flex: 1;
  width: 100%;
`
