import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #fff;
`

export const AboutWrapper = styled.div`
  padding: 4rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`

export const Details = styled.div`
  flex: 1;
  margin-left: 2rem;
  padding: 4rem;
  position: relative;

  &:after,
  &:before {
    opacity: 1
    transition: opacity 0.3s ease-in-out;
    border: 2px solid rgba(84, 91 , 98, 1);
    content: "";
    height: 100px;
    position: absolute;
    width: 100px;
  }
  &:after {
    border-bottom: 0;
    border-left: 0;
    right: 0;
    top: 0;
  }
  &:before {
    border-right: 0;
    border-top: 0;
    bottom: 0;
    left: 0;
  }

  @media (max-width: 960px) {
    margin-left: unset;
    width: 100%;
  }

  li {
    a {
      background-color: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(255, 255, 255, 0) !important;
      color: rgba(84, 91, 98, 1) !important;
      display: block;
      font-size: 30px;
      height: 60px;
      line-height: 60px;
      position: relative;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.4s ease-in-out;
      width: 60px;
      &:after,
      &:before {
        opacity: 0;
        transition: all 0.4s ease-in-out;
        border: 1px solid rgba(0, 0, 0, 0.1);
        content: '';
        height: 20px;
        position: absolute;
        width: 20px;
      }
      &:after {
        border-bottom: 0;
        border-left: 0;
        right: 0;
        top: 0;
      }
      &:before {
        border-right: 0;
        border-top: 0;
        bottom: 0;
        left: 0;
      }
      &:after {
        opacity: 1;
        right: -5px;
        top: -5px;
      }
      &:before {
        bottom: -5px;
        left: -5px;
        opacity: 1;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
        color: rgba(84, 91, 98, 1) !important;
        &:after {
          opacity: 1;
          right: -1px;
          top: -1px;
        }
        &:before {
          bottom: -1px;
          left: -1px;
          opacity: 1;
        }
      }
    }
  }
`
export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`
