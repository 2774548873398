import { IntroWrapper, Wrapper } from './styles'

import { Container } from '../../common'
import { Header } from '../../theme'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Intro = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container} id="home">
        <h1>
          {t('Technical advisor')}, <br />
          DevOps & APIs
        </h1>
        <a href="https://kevinlabory.typeform.com/to/duSHgj">
          {t('I have a project')}
        </a>
      </IntroWrapper>
    </Wrapper>
  )
}
