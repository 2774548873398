import { ContactWrapper, Details, Wrapper } from './styles'

import { Container } from '../../common'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Contact = () => {
  const { t } = useTranslation()
  return (
    <Wrapper id="contact">
      <ContactWrapper as={Container}>
        <Details>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <h2>
                  <span className="font-weight-bold">
                    {t('Having a new project in mind?')}
                  </span>
                </h2>
                <p className="text-muted mx-auto section-subtitle mt-3">
                  {t(
                    'You wish to build up or enhance the R&D capacity of your organisation?'
                  )}{' '}
                  {t('Looking for an experienced freelance developer?')}
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div key={`contact-0`} className="col-lg-4">
              <div className="text-center">
                <div>
                  <a title={t('Call Us On')} href="tel:+33689994981">
                    <i className={`mbri-mobile2 text-custom h1`}></i>
                  </a>
                </div>
                <div className="mt-3">
                  <h5 className="mb-0 contact_detail-title font-weight-bold">
                    {t('Call Us On')}
                  </h5>
                  <p className="text-muted">
                    <a href="tel:+33689994981">+33689994981</a>
                  </p>
                </div>
              </div>
            </div>
            <div key={`contact-1`} className="col-lg-4">
              <div className="text-center">
                <div>
                  <a title={t('Email Us At')} href="mailto:kevinlabory@protonmail.com">
                    <i className={`mbri-letter text-custom h1`}></i>
                  </a>
                </div>
                <div className="mt-3">
                  <h5 className="mb-0 contact_detail-title font-weight-bold">
                    {t('Email Us At')}
                  </h5>
                  <p className="text-muted">
                    <a href="mailto:kevinlabory@protonmail.com">
                      kevinlabory@protonmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div key={`contact-2`} className="col-lg-4">
              <div className="text-center">
                <div>
                  <a title={t('Visit Office')} href="https://goo.gl/maps/RGRRC1WGprVV8Dd9A">
                    <i className={`mbri-pin text-custom h1`}></i>
                  </a>
                </div>
                <div className="mt-3">
                  <h5 className="mb-0 contact_detail-title font-weight-bold">
                    {t('Visit Office')}
                  </h5>
                  <p className="text-muted">
                    <a href="https://goo.gl/maps/RGRRC1WGprVV8Dd9A">
                      149, rue Pierre Sémard 29200 Brest, France.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-2">
            <div className="mt-3">
              <a href="https://kevinlabory.typeform.com/to/duSHgj" className="btn-cta">
                {t('I have a project')}
              </a>
            </div>
          </div>
        </Details>
      </ContactWrapper>
    </Wrapper>
  )
}
