import { Details, ProjectsWrapper, Wrapper } from './styles'
import { graphql, useStaticQuery } from 'gatsby'

import { Container } from '../../common'
import Img from 'gatsby-image'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const Projects = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query WorkImageQuery {
      allFile(
        filter: { relativeDirectory: { eq: "images/works" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper id="projects">
      <ProjectsWrapper as={Container}>
        <Details>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="text-center">
                <h2>
                  <span className="font-weight-bold">{t('Works')}</span>
                </h2>
                <p className="text-muted mx-auto section-subtitle mt-3">
                  {t(
                    'Current and past projects in which I took part as a Technical Advisor and DevOps engineer'
                  )}
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4 work-filter justify-content-center">
            {data.allFile.edges.map((image, idx) => (
              <div key={idx} className="col-lg-4 work-item devlopment webdesign">
                <a
                  href={
                    idx === 0 ? 'https://www.ciwik.com' : 'https://www.nabl.co'
                  }
                  className="img-zoom"
                >
                  <div className="work-box">
                    <div className="work-img img-fluid mx-auto d-block">
                      <Img
                        fluid={image.node.childImageSharp.fluid}
                        alt={image.node.base.split('.')[0]}
                      />
                    </div>
                    <div className="work-detail">
                      <p className="mb-2">Startup</p>
                      <h4 className="mb-0">{image.node.base.split('.')[0]}</h4>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </Details>
      </ProjectsWrapper>
    </Wrapper>
  )
}
